export const enum SettingsActionTypes {
    GET_SALES = 'GET_SALES',
    SET_THEME = 'SET_THEME',
    SET_TOAST = 'SET_TOAST',
    UPDATE_SALE = 'UPDATE_SALE',
    DISABLE_TOUR = 'DISABLE_TOUR',
    SET_PROVIDERS = 'SET_PROVIDERS',
    REQUEST_LOGOUT = 'REQUEST_LOGOUT',
    DISMISS_SURVEY = 'DISMISS_SURVEY',
    SET_APP_LOADING = 'SET_APP_LOADING',
    SET_TERMS_CONSENT = 'SET_TERMS_CONSENT',
    SET_SALES_LOADING = 'SET_SALES_LOADING',
    SET_TENANT_CONFIG = 'SET_TENANT_CONFIG',
    GET_SALES_ARCHIVE = 'GET_SALES_ARCHIVE',
    OPEN_UPDATE_MODAL = 'OPEN_UPDATE_MODAL',
    CLOSE_UPDATE_MODAL = 'CLOSE_UPDATE_MODAL',
    DISMISS_TOUR_ROUTE = 'DISMISS_TOUR_ROUTE',
    SET_QUALTRICS_DATA = 'SET_QUALTRICS_DATA',
    SET_TENANTS_DETAILS = 'SET_TENANTS_DETAILS',
    UPDATE_SALE_ARCHIVE = 'UPDATE_SALE_ARCHIVE',
    SEE_ACTIVATION_POPUP = 'SEE_ACTIVATION_POPUP',
    SET_CAMPAIGN_DETAILS = 'SET_CAMPAIGN_DETAILS',
    SEE_REFERRAL_PROGRAM = 'SEE_REFERRAL_PROGRAM',
    SET_SMART_METER_ALERT = 'SET_SMART_METER_ALERT',
    SEE_MOBILE_APPS_BANNER = 'SEE_MOBILE_APPS_BANNER',
    SET_READING_START_DATE = 'SET_READING_START_DATE',
    SET_MOBILE_WIDGETS_DATA = 'SET_MOBILE_WIDGETS_DATA',
    SEE_HUNGARIAN_MARKETING_WIDGET = 'SEE_HUNGARIAN_MARKETING_WIDGET',
    SET_TADO_RELINKED = 'SET_TADO_RELINKED',
    SET_TADO_UNLINK_STATUS = 'SET_TADO_UNLINK_STATUS',
    SET_SMART_CHARGING_INFO_POPUP_SEEN = 'SET_SMART_CHARGING_INFO_POPUP_SEEN',
    SET_GRIDX_GATEWAY_RESCAN = 'SET_GRIDX_GATEWAY_RESCAN',
}

//TODO: rename this enum
export const enum LOGIN_ERROR_CODES {
    GENERIC = 'GENERIC',
}

export const enum ToastType {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export const enum ToastMessages {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}
