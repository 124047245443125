import {
    CSSProperties,
    FC,
    useState,
    useCallback,
    useMemo,
    useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Joyride, { EVENTS, STATUS, ACTIONS, CallBackProps } from 'react-joyride';

import { Icon, Button } from '@eon-home/react-library';

import { WithScopes } from '@hocs/with-scopes';
import { CloseButton } from '@components';
import { getTourSteps } from './steps';
import { Scope, Routes } from '@tools/enums';
import { setDismissedTourRoutes } from '@store/actions';
import {
    useLoggedIn,
    useAppLoading,
    useAppDarkMode,
    useAppSelector,
    useEnergyProvider,
    useEnergyFlowScenarios,
} from '@store/selectors';

import DemoIconDark from '@assets/icons/menu/demo-light.svg';
import DemoIconLight from '@assets/icons/menu/demo-dark.svg';

import './index.scss';

const tourButtonStyle: CSSProperties = {
    border: 'none',
    padding: '0.5rem',
    fontSize: '1rem',
    lineHeight: 1,
    borderRadius: '0.25rem',
};

export const Tour: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const provider = useEnergyProvider();
    const loggedIn = useLoggedIn();
    const { isDarkMode } = useAppDarkMode();
    const appLoading = useAppLoading();
    const energyState = useEnergyFlowScenarios(true);
    const { pathname } = useLocation() as { pathname: Routes };

    // Check if the pathname is a subpage of ROUTES.MORE and set it to ROUTES.MORE if true to prevent multiple tour popups
    const correctedPathname = pathname.includes(Routes.MORE)
        ? Routes.MORE
        : pathname;

    const tourDisabled = useAppSelector(
        (state) => state.settings.ui.tourDisabled,
    );

    const dismissedTourRoutes = useAppSelector(
        (state) => state.settings.ui.dismissedTourRoutes,
    );

    const [shouldRun, setShouldRun] = useState(false);
    const [stepIndex, setStepIndex] = useState(0);

    const dismissRoute = useCallback(() => {
        dispatch(
            setDismissedTourRoutes([...dismissedTourRoutes, correctedPathname]),
        );
    }, [dispatch, correctedPathname, dismissedTourRoutes]);

    const callback = useCallback(
        ({ type, index, action, status }: CallBackProps) => {
            if (action === ACTIONS.CLOSE) {
                setShouldRun(false);
            }

            if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
                setStepIndex(0);
                setShouldRun(false);
                dismissRoute();
            }

            if (
                type === EVENTS.STEP_AFTER ||
                type === EVENTS.TARGET_NOT_FOUND
            ) {
                setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
            }
        },
        [dismissRoute],
    );

    const backgroundColor = useMemo(
        () =>
            isDarkMode
                ? 'var(--color-primary-background-dark)'
                : 'var(--color-primary-background)',
        [isDarkMode],
    );

    const steps = useMemo(
        () =>
            getTourSteps(t, correctedPathname, provider, loggedIn, energyState),
        [t, correctedPathname, provider, loggedIn, energyState],
    );

    const shouldRender = useMemo(
        () =>
            !appLoading &&
            steps.length > 0 &&
            !dismissedTourRoutes.includes(correctedPathname) &&
            !tourDisabled,
        [
            steps,
            correctedPathname,
            appLoading,
            dismissedTourRoutes,
            tourDisabled,
        ],
    );

    useEffect(() => {
        setStepIndex(0);
        setShouldRun(false);
    }, [correctedPathname]);

    if (!shouldRender) {
        return null;
    }

    return (
        <>
            <Joyride
                run={shouldRun}
                steps={steps}
                locale={{
                    back: t('Back'),
                    last: t('Finish'),
                    next: t('Next'),
                    open: t('Open the dialog'),
                    skip: t('Skip'),
                    close: t('Close'),
                }}
                styles={{
                    options: {
                        zIndex: 300,
                        textColor: 'var(--color-base)',
                        arrowColor: backgroundColor,
                        primaryColor: 'var(--color-primary)',
                        backgroundColor,
                    },
                    buttonBack: {
                        marginRight: '0.375rem',
                        ...tourButtonStyle,
                    },
                    buttonNext: {
                        ...tourButtonStyle,
                    },
                    buttonSkip: {
                        backgroundColor: 'var(--color-secondary)',
                        ...tourButtonStyle,
                    },
                }}
                callback={callback}
                stepIndex={stepIndex}
                continuous
                showSkipButton
                disableScrollParentFix
            />

            <div className="c-tour">
                <WithScopes requiredScopes={[Scope.ME_WRITE]}>
                    <CloseButton onClose={dismissRoute} />
                </WithScopes>

                {isMobileOnly ? (
                    <Button type="button" onClick={() => setShouldRun(true)}>
                        {t('Let me show you around')}
                    </Button>
                ) : (
                    <button
                        type="button"
                        onClick={() => setShouldRun(true)}
                        className="c-tour__button"
                    >
                        <Icon src={isDarkMode ? DemoIconDark : DemoIconLight} />

                        <span>{t('Let me show you around')}</span>
                    </button>
                )}
            </div>
        </>
    );
};

export default Tour;
