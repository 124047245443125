import { HvacDeviceModel, HvacDeviceModelTypeEnum } from '@swagger-http';

import {
    useAppSelector,
    useHasElectricCar,
    useHasWallbox,
} from '@store/selectors';

export const useSmartHomeDevices = () =>
    useAppSelector((state) => state.smartHome.devices);

export const useSmartHomeBranches = () =>
    useAppSelector((state) => state.smartHome.branches);

export const useHasSmartDevices = (
    considerEmobility: boolean = true,
): boolean => {
    const branches = useSmartHomeBranches();
    const wallboxInstalled = useHasWallbox();
    const electricCarInstalled = useHasElectricCar();

    const { smartPlugs, smartLights, heatingCooling } = branches;

    return Boolean(
        considerEmobility
            ? smartLights ||
                  smartPlugs ||
                  heatingCooling ||
                  wallboxInstalled ||
                  electricCarInstalled
            : smartLights || smartPlugs || heatingCooling,
    );
};

export const useHeatingDevices = () => {
    const devices = useSmartHomeDevices();
    return devices.filter(
        (device: HvacDeviceModel) =>
            device.type === HvacDeviceModelTypeEnum.Heating ||
            device.type === HvacDeviceModelTypeEnum.AirConditioning,
    );
};
